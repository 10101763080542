import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import keys from 'lodash/keys';
import map from 'lodash/map';
import flatten from 'lodash/flatten';
import filter from 'lodash/filter';
import slice from 'lodash/slice';
import useContentful from 'src/helpers/hooks/useContentful';
import { Grid } from 'antd';
import PageLayout from 'src/components/Layout';
import HomeMobile from 'src/components/home/HomeMobile';
import HomeDesktop from 'src/components/home/HomeDesktop';
import useGetEvents from '../helpers/hooks/useGetEvents';

const { useBreakpoint } = Grid;

const query = `
{
  homePage(id: "3j5iGwv2sPh8prQJGRFUKF") {
    sliderPhotosCollection {
      items {
        fileName
        url
        width
        height
      }
    }
  }
  
  calendar(id: "3ovEdkWYmroA4k2isMcrgJ") {
    events
    pageHeader {
      pageTitle
      pagePhotoCollection {
        items {
          fileName
          url
          width
          height
        }
      }
    }
  }
  
  bioPage(id: "4kMXvXbTCIyXr0YgTke8fE") {
    pageHeader {
      pageTitle
    }
    paragraphEducation {
     json 
    }
    paragraphCooperation {
     json 
    }
    highlightedText
  }
  
  reviewsPage(id: "4Xw3hAmkVLEgTfgXNU5RXR") {
    pageHeader {
      pageTitle
    }
    reviews {
      reviews
    }
  }
}
`;

// eslint-disable-next-line func-names
const RootIndex = function ({ location }) {
  const { lg } = useBreakpoint();
  const { data, loading } = useContentful(query);
  const homePagePhotos = get(data, 'homePage.sliderPhotosCollection.items') || [];
  const events = get(data, 'calendar.events.list') || [];
  const calendarTitle = get(data, 'calendar.pageHeader.pageTitle');

  const paragraphEducation = get(data, 'bioPage.paragraphEducation.json');
  const paragraphCooperation = get(data, 'bioPage.paragraphCooperation.json');
  const bioHighlightedText = get(data, 'bioPage.highlightedText');
  const biographyTitle = get(data, 'bioPage.pageHeader.pageTitle');

  const reviewsTitle = get(data, 'reviewsPage.pageHeader.pageTitle');
  const reviews = get(data, 'reviewsPage.reviews.reviews');

  const getSelectedReviews = () => filter(
    flatten(map(keys(reviews), (key) => reviews[key])),
    (review) => review.review.length >= 190 && review.review.length <= 300,
  );

  const selectedReviews = getSelectedReviews();

  const { incomingEvents } = useGetEvents(events);

  const nearestEvents = slice(incomingEvents, 0, 10);

  return (
    <PageLayout
      location={location}
      loading={loading}
      isHomepage
    >
      {lg ? (
        <HomeDesktop
          homePagePhotos={homePagePhotos}
          events={nearestEvents}
          calendarTitle={calendarTitle}
          paragraphEducation={paragraphEducation}
          biographyTitle={biographyTitle}
          paragraphCooperation={paragraphCooperation}
          bioHighlightedText={bioHighlightedText}
          reviewsTitle={reviewsTitle}
          reviews={selectedReviews}
        />
      ) : (
        <HomeMobile
          homePagePhotos={homePagePhotos}
          events={nearestEvents}
          calendarTitle={calendarTitle}
          paragraphEducation={paragraphEducation}
          biographyTitle={biographyTitle}
          bioHighlightedText={bioHighlightedText}
          reviewsTitle={reviewsTitle}
          reviews={selectedReviews}
        />
      )}
    </PageLayout>
  );
};

RootIndex.propTypes = {
  location: PropTypes.object.isRequired,
};

export default RootIndex;
