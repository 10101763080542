import React from 'react';
import map from 'lodash/map';
import { Carousel, Divider, Typography } from 'antd';
import { Link } from 'gatsby';
import CalendarList from 'src/components/calendar/CalendarList';
import RichText from 'src/components/RichText';
import PropTypes from 'prop-types';
import PageTitle from '../PageTitle';
import ReviewsListRow from '../reviews/ReviewsListRow';

const { Paragraph } = Typography;

// eslint-disable-next-line func-names
const HomeMobile = function ({
  homePagePhotos,
  events,
  calendarTitle,
  paragraphEducation,
  biographyTitle,
  reviewsTitle,
  reviews,
  bioHighlightedText,
}) {
  return (
    <>
      <div className='homepage-carousel'>
        <Carousel
          key='images-slider'
          effect='fade'
          speed={3000}
          autoplay
          autoplaySpeed={6000}
        >
          {map(homePagePhotos, (photo) => (
            <div key={photo.url}>
              <img
                src={photo.url}
                alt={photo.fileName}
              />
            </div>
          ))}
        </Carousel>
      </div>
      <div className='home-page__biography'>
        <PageTitle title={biographyTitle} />
        <Paragraph
          strong
          italic
          className='home-page-desktop__biography--highlighted'
        >
          {bioHighlightedText}
        </Paragraph>
        <RichText text={paragraphEducation} />
        <div
          className='custom-button'
        >
          <Link
            to='/about'
          >
            <span>READ FULL BIO</span>
          </Link>
        </div>
      </div>
      <Divider />
      <div className='home-page__reviews'>
        <PageTitle title={reviewsTitle} />
        <Carousel
          autoplay
          autoplaySpeed={6000}
        >
          {map(reviews, (review) => (
            <ReviewsListRow data={review} />
          ))}
        </Carousel>
        <div
          className='custom-button'
        >
          <Link
            to='/about'
          >
            <span>READ ALL REVIEWS</span>
          </Link>
        </div>
      </div>
      <Divider />
      <div className='home-page__schedule'>
        <PageTitle title={calendarTitle} />
        <CalendarList
          data={events}
          withLoadMore={false}
        />
        <div
          className='custom-button'
        >
          <Link
            to='/schedule'
          >
            <span>READ FULL SCHEDULE</span>
          </Link>
        </div>
      </div>
    </>
  );
};

HomeMobile.propTypes = {
  biographyTitle: PropTypes.string.isRequired,
  bioHighlightedText: PropTypes.string.isRequired,
  calendarTitle: PropTypes.string.isRequired,
  events: PropTypes.array.isRequired,
  homePagePhotos: PropTypes.array.isRequired,
  paragraphEducation: PropTypes.object.isRequired,
  reviews: PropTypes.array.isRequired,
  reviewsTitle: PropTypes.string.isRequired,
};

export default HomeMobile;
