import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { useOnLoadImages } from 'src/helpers/hooks/useOnLoadImages';
import {
  Typography, Row, Col, Spin, Space, Carousel,
} from 'antd';
import { Link } from 'gatsby';
import Image from 'src/components/Image';
import PageTitle from 'src/components/PageTitle';
import CalendarList from 'src/components/calendar/CalendarList';
import RichText from 'src/components/RichText';
import ReviewsListRow from 'src/components/reviews/ReviewsListRow';

const { Paragraph } = Typography;
// eslint-disable-next-line func-names
const HomeDesktop = function ({
  homePagePhotos,
  events,
  calendarTitle,
  biographyTitle,
  paragraphEducation,
  bioHighlightedText,
  reviewsTitle,
  reviews,
}) {
  const wrapperRef = useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);
  return (
    <>
      {!imagesLoaded && (
        <div className='loader-container'>
          <Spin size='large' />
        </div>
      )}
      <div
        className='home-page-desktop__hero'
        ref={wrapperRef}
      >
        <div className='home-page-desktop__hero--images'>
          {map(homePagePhotos, (photo) => (
            <div
              key={photo.fileName}
              className='home-hero-image'
            >
              <Image
                src={photo.url}
                alt={photo.fileName}
              />
            </div>
          ))}
        </div>
        <div className='home-page-desktop__hero--title'>
          <h1>
            Małgorzata Rocławska
          </h1>
          <span className='home-identity-line' />
          <span className='home-identity-profession'>soprano</span>
        </div>
      </div>

      <Space
        direction='vertical'
        size={100}
        style={{
          width: '100%',
        }}
      >
        <div className='home-page-desktop__biography'>
          <Space
            direction='vertical'
            size={30}
            style={{
              width: '100%',
            }}
          >
            <Row gutter={36}>
              <Col span={12}>
                <PageTitle title={biographyTitle} />
                <Paragraph
                  strong
                  italic
                  className='home-page-desktop__biography--highlighted'
                >
                  {bioHighlightedText}
                </Paragraph>
              </Col>
              <Col span={12}>
                <RichText text={paragraphEducation} />
              </Col>
            </Row>
            <div
              className='custom-button'
            >
              <Link
                to='/about'
              >
                <span>READ FULL BIO</span>
              </Link>
            </div>
          </Space>
        </div>

        <div className='home-page-desktop__reviews'>
          <PageTitle
            title={reviewsTitle}
            style={{
              textAlign: 'center',
            }}
          />
          <Carousel
            autoplay
            autoplaySpeed={6000}
          >
            {map(reviews, (review) => (
              <ReviewsListRow data={review} />
            ))}
          </Carousel>
          <div
            className='custom-button'
          >
            <Link
              to='/about'
            >
              <span>READ ALL REVIEWS</span>
            </Link>
          </div>
        </div>

        <div className='home-page__schedule'>
          <Row
            gutter={36}
            justify='center'
          >
            <Col
              xs={20}
              xxl={18}
            >
              <PageTitle
                title={calendarTitle}
                style={{
                  textAlign: 'center',
                }}
              />
              <Space
                direction='vertical'
                size={30}
                style={{
                  width: '100%',
                }}
              >
                <CalendarList
                  data={events}
                  withLoadMore={false}
                />
                <div
                  className='custom-button'
                >
                  <Link
                    to='/schedule'
                  >
                    <span>READ FULL SCHEDULE</span>
                  </Link>
                </div>
              </Space>
            </Col>
          </Row>
        </div>
      </Space>
    </>
  );
};

HomeDesktop.propTypes = {
  biographyTitle: PropTypes.string.isRequired,
  bioHighlightedText: PropTypes.string.isRequired,
  calendarTitle: PropTypes.string.isRequired,
  events: PropTypes.array.isRequired,
  homePagePhotos: PropTypes.array.isRequired,
  paragraphEducation: PropTypes.object.isRequired,
  reviews: PropTypes.array.isRequired,
  reviewsTitle: PropTypes.string.isRequired,
};

export default HomeDesktop;
